import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ScrollToAnchor from '~/src/components/scroll-to-anchor';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ScrollToAnchor mdxType="ScrollToAnchor" />
    <h2>{`Spacing and layout`}</h2>
    <p>{`In general, our apps conform to an 8-point grid layout. However, we defer many component sizes, spacings, and layouts to the platform (iOS + Android) in order to let them deal with cross-device responsiveness, tablet transformations, and OS accessibility settings. When designing custom components, make sure elements are spaced and aligned to an 8-point grid (half-steps of 4pts are included).`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/"
        }}>{`HIG: Adaptivity and Layout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/layout/spacing-methods.html#baseline-grid"
        }}>{`Material Spacing`}</a></li>
    </ul>
    <h2>{`Color`}</h2>
    <p>{`Because we support dark mode and high contrast color variants, working with colors on mobile requires a few special workflows and considerations. Our core color system is maintained in the `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/csCViryWkkXwBsK1aLrO0X/Mobile-Design-Toolkit?node-id=1%3A532"
      }}>{`Mobile Design Toolkit`}</a>{` Figma file. This is the source of truth. Changes here will propogate out to the Figma Team Library, the `}<a parentName="p" {...{
        "href": "https://www.figma.com/community/plugin/848205889935107396/Mobile-Design-Toolkit"
      }}>{`Mobile Design Toolkit`}</a>{` plugin, and eventually into the iOS and Android apps as Xcassets and XML files.`}</p>
    <p>{`Adding and updating colors does take time and attention to detail. This should not deter us from iterating, adding new colors, or adjust color values when we find they are not working in production. The following notes and videos are meant to capture the existing processes.`}</p>
    <h3>{`Primer base`}</h3>
    <p>{`We use the `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/B5XPE8IwGPIZDAvN7jqWqx/Primer-Primitives?node-id=0%3A1&viewport=609%2C529%2C0.383825421333313"
      }}>{`Primer Primitives`}</a>{` color values as our starting point for working with color in mobile. We always want to be inheriting upstream changes to different values.`}</p>
    <img width="1638" alt="Primer Primitives base colors displayed together" src="https://user-images.githubusercontent.com/1923260/87474815-f01d0000-c5e0-11ea-9b22-b6f43ad073c6.png" />
    <h3>{`Spectrum colors`}</h3>
    <p>{`Because the mobile apps support dark mode, we want to make minor adjustments to the Primer colors so that they remain clear in a dark environment. Generally, we do this by increasing brightness (improve contrast) and decreasing saturation (reducing vibration). By doing this, we end up with a light/dark pairing for each color in the Primer system.`}</p>
    <img width="1641" alt="Primer colors extended to support a dark mode variant" src="https://user-images.githubusercontent.com/1923260/87474820-f14e2d00-c5e0-11ea-9891-9c71fe5776d8.png" />
    <h3>{`Functional colors`}</h3>
    <p>{`When we begin implementing colors into the codebase, we immediately bump into two problems:`}</p>
    <ol>
      <li parentName="ol">{`Using Spectrum values like `}<inlineCode parentName="li">{`gray-500`}</inlineCode>{` are not descriptive, and will lead to confusion about when to use which value`}</li>
      <li parentName="ol">{`To support dark mode and high contrast mode, we would have to use four separate Spectrum values to account for each variant (e.g. `}<inlineCode parentName="li">{`gray100`}</inlineCode>{` in dark mode, `}<inlineCode parentName="li">{`gray900`}</inlineCode>{` in light mode).`}</li>
    </ol>
    <p>{`To combat this, we abstract our Spectrum values into `}<em parentName="p">{`Functional colors`}</em>{`. Functional colors describe their purpose in the application. For example, `}<inlineCode parentName="p">{`iconTertiary`}</inlineCode>{` or `}<inlineCode parentName="p">{`textPrimary`}</inlineCode>{`. These values are more readable to humans, and provide inherent context about when they should be used.`}</p>
    <p>{`Additionally, functional colors `}<em parentName="p">{`abstract dark mode and high contrast mode`}</em>{`. For example, a functional color `}<inlineCode parentName="p">{`textSecondary`}</inlineCode>{` might wrap the following sets of rules:`}</p>
    <ul>
      <li parentName="ul">{`In light mode, normal contrast, use `}<inlineCode parentName="li">{`gray800`}</inlineCode></li>
      <li parentName="ul">{`In light mode, high contrast, use `}<inlineCode parentName="li">{`gray850`}</inlineCode></li>
      <li parentName="ul">{`In dark mode, normal contrast, use `}<inlineCode parentName="li">{`gray200`}</inlineCode></li>
      <li parentName="ul">{`In dark mode, high contrast, use `}<inlineCode parentName="li">{`gray150`}</inlineCode></li>
    </ul>
    <p>{`Using code generation, we can dynamically extract XML files for Android, and Xcassets for iOS that contain these color abstractions.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Our functional color system is a work in progress! We currently cover text, icons, backgrounds, badges, and borders. But we should expect to expand and refine this system over time as we encounter new use cases and opportunities for clear abstraction.`}</p>
    <img width="1010" alt="Example functional color set up showing icon colors" src="https://user-images.githubusercontent.com/1923260/87474825-f1e6c380-c5e0-11ea-92b7-56e3c983fc5d.png" />
    <h2>{`Iconography`}</h2>
    <p>{`Our mobile apps use GitHub's icon system, `}<a parentName="p" {...{
        "href": "/octicons"
      }}>{`Octicons`}</a>{`. Octicons come in two sizes, 16px and 24px.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/primer/octicons"
      }}>{`Star the Octicons repo`}</a>{` to follow along with the Octicons project. Open issues and pull requests to get support or ask for new icon additions to the system.`}</p>
    <h3>{`Icon colors`}</h3>
    <p>{`In the Mobile Design Toolkit we have two functional icon colors: `}<inlineCode parentName="p">{`iconPrimary`}</inlineCode>{` and `}<inlineCode parentName="p">{`iconSecondary`}</inlineCode>{`. Each of these colors implements a different brightness of gray, adding visual prominence to more important or actionable icons. But when should you use either of these colors? And when should an icon be gray versus our blue `}<inlineCode parentName="p">{`link`}</inlineCode>{` color?`}</p>
    <h4>{`Link blue icons`}</h4>
    <p>{`In general, an icon should be blue if it is:`}</p>
    <ul>
      <li parentName="ul">{`Used in a navigation bar or toolbar. In other words, it is part of the app chrome.`}</li>
      <li parentName="ul">{`It is a primary, highly-desirable action for a user to take on any given screen.`}</li>
    </ul>
    <p>{`We strive to use as few `}<inlineCode parentName="p">{`link`}</inlineCode>{` tinted elements in a content view in order to draw attention to only the most important actionable interface elements. Adding too many blue buttons and icons dilutes the power of the color as a way to draw attention.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/87456404-1ed8ad80-c5c4-11ea-8fbf-4f252a429881.png",
              "alt": "repo profile example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`On the repo profile, we follow our rules that nav bar iconography is `}<inlineCode parentName="td">{`link`}</inlineCode>{` blue, because it's part of the app's chrome. The icons in the `}<inlineCode parentName="td">{`Star`}</inlineCode>{` and `}<inlineCode parentName="td">{`Watch`}</inlineCode>{` buttons are also blue to match the button labels and draw attention to the highly-desirable actions that we want users to take on this screen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/87456413-213b0780-c5c4-11ea-95d9-b0932af9c2d4.png",
              "alt": "triage sheet example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In the triage sheet, the primary purpose of this sheet is to manage assignees for a given issue. Because the `}<em parentName="td">{`purpose`}</em>{` of this view is to manage (or more likely, to add) assignees, we make the `}<inlineCode parentName="td">{`( + )`}</inlineCode>{` icons blue.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Gray icons`}</h4>
    <p>{`Generally, an icon could be filling one of four roles:`}</p>
    <ul>
      <li parentName="ul">{`An icon that represents and `}<em parentName="li">{`important actionable activity`}</em>{`, but is not the primary purpose of the view`}</li>
      <li parentName="ul">{`An icon that represents an `}<em parentName="li">{`unimportant actionable activity`}</em></li>
      <li parentName="ul">{`An icon that is `}<em parentName="li">{`not actionable`}</em>{` and serves as a `}<em parentName="li">{`primary`}</em>{` supporting graphic of a piece of text or a view`}</li>
      <li parentName="ul">{`An icon that is `}<em parentName="li">{`not actionable`}</em>{` and only serves as a `}<em parentName="li">{`decorative`}</em>{` accessory`}</li>
    </ul>
    <p>{`Generally, important actionable elements and primary supporting graphics should use `}<inlineCode parentName="p">{`iconPrimary`}</inlineCode>{`, while unimportant actionable elements and decorative accessories use `}<inlineCode parentName="p">{`iconSecondary`}</inlineCode>{`.`}</p>
    <p>{`Let's look at some examples:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214970-3b09c980-cc18-11ea-91f4-7f470bbda304.png",
              "alt": "user profile example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The header metatdata icons are unimportant decorative accessories, and not actionable, so we tint them `}<inlineCode parentName="td">{`secondary`}</inlineCode>{`. The Pinned section icon is not actionable, but is an important accessory to distinguish this content section, so it is tinted `}<inlineCode parentName="td">{`primary`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214976-3d6c2380-cc18-11ea-986b-f91d646f236f.png",
              "alt": "settings example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The accessory icons are not actionable, and not important decorations, so they are tinted `}<inlineCode parentName="td">{`secondary`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214979-3e04ba00-cc18-11ea-9fee-051fc94a1854.png",
              "alt": "repo profile example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The branch icon in the code browsing section header is an unactionable accessory tinted `}<inlineCode parentName="td">{`secondary`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214981-3e9d5080-cc18-11ea-827c-2e0f19611677.png",
              "alt": "commit details example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The commit icon is an important accessory, despite not being actionable, so it is tinted `}<inlineCode parentName="td">{`primary`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214984-3f35e700-cc18-11ea-8722-dd3106611c34.png",
              "alt": "search typeahead example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The search typeahead icons are important supporting accessories to quickly distinguish the typeahead options, so they are tinted `}<inlineCode parentName="td">{`primary`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214987-3fce7d80-cc18-11ea-8831-065d30dd4ff5.png",
              "alt": "edit favorites example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The remove buttons when editing favorites are actionable, but not the primary action on this view, so they are tinted `}<inlineCode parentName="td">{`secondary`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Other icon colors`}</h4>
    <p>{`It's possible for icons to be other colors besides gray and blue! For example, we use yellow star icons to indicate that you, the viewer, have starred a repository. We also use green eye icons to indicate that you are watching a repository. You might also find pink hearts when working with sponsors. And of course, we use green, red, and purple as issue and pull request icon tints to represent states (open, closed, merged).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/87458726-9fe57400-c5c7-11ea-86a1-b057644a45ac.png",
              "alt": "starred repo example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In this example, the Star button gets a gray label and yellow icon to communicate the `}<em parentName="td">{`state`}</em>{` of the viewer's relationship to this repository. The gray label de-emphasizes the button, because un-starring is a generally undesirable action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/1923260/88214981-3e9d5080-cc18-11ea-827c-2e0f19611677.png",
              "alt": "commit details example"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state of the PR where this commit was created is tinted purple to indicate the PR was merged, therefore this commit was merged, too.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Typography`}</h2>
    <p>{`While we can define our own custom type styles at any time, we prefer to lean on system defaults. System defaults allow us to inherit an enormous amount of functionality for free: dynamic type sizes, bi-directionality, dark mode, voiceover, internationalization, and more.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/typography/"
        }}>{`iOS Typography Guidelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/typography/the-type-system.html#type-scale"
        }}>{`The Material Design Type Scale`}</a></li>
    </ul>
    <p>{`Our Android app takes advantage of the `}<a parentName="p" {...{
        "href": "https://material.io/design/material-theming/overview.html#material-theming"
      }}>{`Material Theming`}</a>{` spec that allows us to use `}<a parentName="p" {...{
        "href": "https://rsms.me/inter/"
      }}>{`Inter`}</a>{` as our default typeface, providing a more clear reading experience for interface text.`}</p>
    <h3>{`Type colors`}</h3>
    <p>{`There are currently six functional colors used in the mobile toolkit:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`textPrimary`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`textSeconary`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`textTertiary`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`textPlaceholder`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`link`}</inlineCode></li>
    </ul>
    <p>{`It is up to each designer's judgment, and through our critique process, to decide on the correct text values used for a screen. In general, for the `}<inlineCode parentName="p">{`primary`}</inlineCode>{` → `}<inlineCode parentName="p">{`tertiary`}</inlineCode>{` values, these should map closely to the visual hierarchy or importance hierarchy of content on a screen.`}</p>
    <img width="578" alt="Image showing a repo profile list item and how typographic hierarchy maps to colors" src="https://user-images.githubusercontent.com/1923260/88214609-b159fc00-cc17-11ea-954b-cfafcc42936c.png" />
    <p>{`The `}<inlineCode parentName="p">{`link`}</inlineCode>{` color will render a blue color, affording an actionable element on the screen (like button, or a link).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      